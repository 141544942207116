import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (

    <div className="video-container">
        <iframe
            src={videoSrcURL}
            frameBorder="0"
            allowFullScreen
        />
    </div>
    
)
export default Video