import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Video from "../components/video"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import ReactCompareImage from 'react-compare-image';

class PortfolioTemplate extends React.Component {

	render() {

		const pathname = this.props.location.pathname;
		const portfolio = this.props.data.wordpressWpRedderPortfolio;

		return (

			<Layout pageName="Work" additionalClass="portfolio">

			<SEO title={portfolio.yoast.title} description={portfolio.yoast.metadesc} pathname={pathname} />

			{portfolio.acf.header_image ?
			
			<BackgroundImage
				className="bgImage"
				fluid={portfolio.acf.header_image.localFile.childImageSharp.fluid}
			/>

			: 
			<BackgroundImage
				className="bgImage"
				fluid={portfolio.acf.featured_image.localFile.childImageSharp.fluid}
			/>
			
			}

			{portfolio.acf.description || portfolio.title ? 

				<div className="description">
					<div className="container">
						<h1 dangerouslySetInnerHTML={{ __html: portfolio.title }} />
							<div className="tags"> 
							{portfolio.acf.categories &&
								portfolio.acf.categories.map((node, index) =>
									<div key={index} className="tag" dangerouslySetInnerHTML={{ __html: node.name }} />
							)}
							</div>
						<div className="text" dangerouslySetInnerHTML={{ __html: portfolio.acf.description }} />
					</div>
				</div>			
			
			: null }

			
			{portfolio.acf.content_block_redder_portfolio &&
			portfolio.acf.content_block_redder_portfolio.map((layout, index) => {

				if (layout.__typename === `WordPressAcf_image_slider`) {

					const before_image = layout.before_image.localFile.childImageSharp.fluid.src
					const after_image = layout.after_image.localFile.childImageSharp.fluid.src

					return (
						<div className="image-slider">
							<div className="container">
								<ReactCompareImage
									leftImage={before_image}
									rightImage={after_image}
									sliderLineColor="#32323c"
									handleSize="75"
									handle={<button className="customHandle" />}
								/>
							</div>
						</div>
					)
				}

				if (layout.__typename === `WordPressAcf_single_image`) {

					const image = layout.image.localFile.childImageSharp.fluid

					return (
						<div key={index} className="single-image">
							<div className="container">
								<Img fluid={image} />
							</div>
						</div>
					)
				}

				if (layout.__typename === `WordPressAcf_content`) {
					return (
						<div key={index} className="content">
							<div className="container">
								<div dangerouslySetInnerHTML={{ __html: layout.editor }} />
							</div>
						</div>
					)
				}

				if (layout.__typename === `WordPressAcf_video`) {
					return (
						<div key={index} className="content">
							<div className="container">
								<Video
									videoSrcURL={layout.video_url}
								/>
							</div>
						</div>
					)
				}

				if (layout.__typename === `WordPressAcf_two_column`) {
						
					const left_image = layout.left_image ? layout.left_image.localFile.childImageSharp.fluid : "";
					const right_image = layout.right_image ? layout.right_image.localFile.childImageSharp.fluid : "";

					return (

						<div className="two-column">

							<div className="container">

								{layout.left_image_or_content === `image` ?
									<div className="left">
										<Img fluid={left_image} />
									</div>
								:
									<div className="left">
										<div>left content</div>
									</div>
								}

								{layout.right_image_or_content === `image` ?
								
									<div className="right">
										<Img fluid={right_image} />
									</div>
								:
									<div className="right">
										<div>right content</div>
									</div>
								}

							</div>

						</div>
					)
				}

			})}

			<div className="container">
				<div className="page-nav">
					{this.props.pageContext.pageNav.previous &&
						<Link className="prev" to={`/work/${this.props.pageContext.pageNav.previous.slug}`}>
							<span className="icon-chevron-thin-left"></span>
							<span className="text">Previous</span>
						</Link>
					}
					{this.props.pageContext.pageNav.next && 
						<Link className="next" to={`/work/${this.props.pageContext.pageNav.next.slug}`}>
							<span className="text">Next</span>
							<span className="icon-chevron-thin-right"></span>
						</Link>
					}
				</div>
			</div>
			
		</Layout>

		)
	}

}

export default PortfolioTemplate

export const pageQuery = graphql`
	query($id: String!) {
		wordpressWpRedderPortfolio(id: { eq: $id }) {
			title
			content
			acf {
				header_image {
					localFile {
						childImageSharp {
							fluid(maxWidth: 3000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image {
					localFile {
						childImageSharp {
							fluid(maxWidth: 3000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				description
				categories {
					slug
					name
				}
				content_block_redder_portfolio {
					__typename
						... on WordPressAcf_image_slider {
							after_image {
								localFile {
									childImageSharp {
										fluid(maxWidth: 3000) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							before_image {
								localFile {
									childImageSharp {
										fluid(maxWidth: 3000) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on WordPressAcf_single_image {
							image {
								localFile {
									childImageSharp {
										fluid(maxWidth: 3000) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on WordPressAcf_two_column {
							left_image_or_content
							left_content
							left_image {
								localFile {
									childImageSharp {
										fluid(maxWidth: 3000) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							right_image_or_content
							right_content
							right_image {
								localFile {
									childImageSharp {
										fluid(maxWidth: 3000) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on WordPressAcf_content {
							editor
						}
						... on WordPressAcf_video {
							video_url
						}
				}
			}
			yoast{
				title
				metadesc
			}
		},
		fileName: file(relativePath: { eq: "Lola.jpeg" }) {
			...fluidImage
		}
	}
`